@import '~antd/dist/antd.dark.less';



@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  margin: 0;
  padding: 0;
}
@primary-color: #fe4531;@body-background: #56565e;@component-background: #1f1e33;