.button-container {
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}

.form-container {
  padding: 10px 0;
}

.textarea-default {
  flex: 1;
}

.input-default {
  margin: 10px 5px 10px 5px;
  flex: 1;
}

.form-column {
  padding: 5px;
}

.form-button {
  width: 100%;
}

.flex1 {
  flex: 1;
}

.mdEditor {
  min-width: 100%;
  margin: 6px;
}

.mb16 {
  margin-bottom: 16px;
}

.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortable-list {
  height: 250px;
  overflow-y: scroll;
}

.mv16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mt16 {
  margin-top: 16px;
}

.issue-selector {
  width: 100%;
  height: 100%;
}

.flush-ant-input {
  border: none;
  background: transparent;
  resize: none;
  box-shadow: none;
}

.mr6 {
  margin-right: 6px;
}

.m16 {
  margin: 16px;
}

.mt5 {
  margin-top: 5px;
}

.worklog-editor-container {
  min-height: 100%;
}

.m6 {
  margin: 6px;
}

.mh100 {
  min-height: 100%;
}
